var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React, { useEffect, useMemo, useState } from 'react';
import { replaceUrl } from '../utils/url';
var MARGIN_HEIGHT = 10;
var EmbeddedLocaopReservation = function (_a) {
    var block = _a.block;
    var _b = useState(0), height = _b[0], setHeight = _b[1];
    var searchParams = new URL(window.location.href).searchParams;
    var inflowSource = searchParams.get('_src') || window.document.referrer;
    var containerElem = React.createRef();
    useEffect(function () {
        var messageHandler = function (event) {
            var _a;
            if (block.url) {
                var url_1 = new URL(block.url);
                if (url_1.origin !== event.origin) {
                    return;
                }
                if (event.data.type === 'locaop-reservation-iframe-height' &&
                    event.data.height) {
                    var newHeight = event.data.height + MARGIN_HEIGHT;
                    // 高さは大きくなることはあっても小さくなることはない
                    // 小さくなることで画面がブレるの防ぐため
                    if (height < newHeight) {
                        setHeight(newHeight);
                    }
                }
                else if (event.data.type === 'locaop-reservation-show-course-page' ||
                    event.data.type === 'locaop-reservation-show-form-page' ||
                    event.data.type === 'locaop-reservation-show-thanks-page') {
                    // フォーム画面を表示するときにスクロールして表示する
                    // instantが型エラーになるのでanyで回避
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (_a = containerElem.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: 'instant',
                        block: 'start',
                    });
                    // 高さが変わることがあるので、高さを調整する
                    if (event.data.height) {
                        setHeight(event.data.height);
                    }
                }
            }
        };
        window.addEventListener('message', messageHandler);
        return function () { return window.removeEventListener('message', messageHandler); };
    }, [block.url, height]);
    var url = useMemo(function () {
        if (block.url) {
            var baseUrl = replaceUrl(block.url, inflowSource);
            var url_2 = new URL(baseUrl);
            url_2.searchParams.set('widget', '1');
            if (block.normalMode) {
                url_2.searchParams.set('normal', '1');
            }
            return url_2.toString();
        }
    }, [block.url, block.normalMode]);
    var heightStyle = useMemo(function () {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      height: ", "px;\n    "], ["\n      height: ", "px;\n    "])), height);
    }, [height]);
    return (_jsx("section", __assign({ ref: containerElem, className: 'w-full lg:w-3/4 my-4 m-auto', css: heightStyle }, { children: _jsx("div", __assign({ className: 'aspect-w-16 aspect-h-9', css: heightStyle }, { children: _jsx("iframe", { loading: 'lazy', title: '\u4E88\u7D04\u306E\u57CB\u3081\u8FBC\u307F', src: url, frameBorder: '0', allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture', allowFullScreen: true, css: heightStyle }) })) })));
};
export default EmbeddedLocaopReservation;
var templateObject_1;
