var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
/* eslint-disable no-fallthrough */
import { css } from '@emotion/react';
import React, { useEffect, useState } from 'react';
import { getParam } from '../utils/getParam';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../utils/scroll-message-sender';
import BannerLinks from './BannerLinks';
import Conversion from './Conversion';
import EmbeddedIframe from './EmbeddedIframe';
import EmbeddedInstagram from './EmbeddedInstagram';
import EmbeddedLocaopReservation from './EmbeddedLocaopReservation';
import EmbeddedYouTube from './EmbeddedYouTube';
import FormBlock from './FormBlock';
import GoogleMap from './GoogleMap';
import HeadingAndDescription from './HeadingAndDescription';
import Html from './Html';
import Image from './Image';
import LinkCollection from './LinkCollection';
import MultiYouTube from './MultiYouTube/MultiYouTube';
import QandA from './QandA';
import MultiRichTextEditor from './RichTextEditor/MultiRichTextEditor';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import SlideShowBlockContent from './SlideShowBlockContent';
import WordOfMouth from './WordOfMouth';
var SiteContents = function (_a) {
    var uid = _a.uid, setting = _a.setting, icons = _a.icons, shopReviews = _a.shopReviews;
    var _b = useState(), deviceType = _b[0], setDeviceType = _b[1];
    var breakpoints = 768;
    var getDeviceType = function () {
        return window.innerWidth < breakpoints ? 'mobile' : 'pc';
    };
    useEffect(function () {
        setDeviceType(getDeviceType());
        var handleResize = function () { return setDeviceType(getDeviceType()); };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    var buildBlock = function (block) {
        if (!block.hidden) {
            switch (block.type) {
                case 'text':
                    return _jsx(HeadingAndDescription, { block: block }, block.uid);
                case 'richTextEditor':
                    return _jsx(RichTextEditor, { block: block }, block.uid);
                case 'multiRichTextEditor':
                    return _jsx(MultiRichTextEditor, { block: block }, block.uid);
                case 'conversion':
                    return _jsx(Conversion, { block: block }, block.uid);
                case 'links':
                    return (_jsx(LinkCollection, { block: block, color: setting.themeColor, icons: icons, setting: setting }, block.uid));
                case 'map':
                    return _jsx(GoogleMap, { block: block }, block.uid);
                case 'slideshow':
                    return _jsx(SlideShowBlockContent, { block: block }, block.uid);
                case 'bannerlinks':
                    return _jsx(BannerLinks, { block: block }, block.uid);
                case 'image':
                    return _jsx(Image, { block: block }, block.uid);
                case 'iframe':
                    return _jsx(EmbeddedIframe, { block: block }, block.uid);
                case 'locaopReservation':
                    return _jsx(EmbeddedLocaopReservation, { block: block }, block.uid);
                case 'youtube':
                    return _jsx(EmbeddedYouTube, { block: block }, block.uid);
                case 'multiYoutube':
                    return _jsx(MultiYouTube, { block: block }, block.uid);
                case 'instagram':
                    return _jsx(EmbeddedInstagram, { block: block }, block.uid);
                case 'form':
                    return _jsx(FormBlock, { block: block, uid: uid }, block.uid);
                case 'reviews': {
                    if (shopReviews) {
                        return (_jsx(WordOfMouth, { setting: setting, block: block, shopReviews: shopReviews }, block.uid));
                    }
                    else {
                        var mode = getParam('mode');
                        if (mode === 'preview') {
                            return (_jsx("div", { children: "\u30B5\u30A4\u30C8\u306E\u65B0\u898F\u4F5C\u6210\u6642\u306F\u30D7\u30EC\u30D3\u30E5\u30FC\u3067\u53E3\u30B3\u30DF\u30D6\u30ED\u30C3\u30AF\u306E\u8868\u793A\u306F\u3067\u304D\u307E\u305B\u3093\u3002\u30B5\u30A4\u30C8\u4FDD\u5B58\u5F8C\u306B\u518D\u5EA6\u7DE8\u96C6\u753B\u9762\u3092\u958B\u3044\u3066\u78BA\u8A8D\u3092\u304A\u9858\u3044\u3057\u307E\u3059\u3002" }));
                        }
                        else {
                            return _jsx("div", { children: "\u53E3\u30B3\u30DF\u3092\u53D6\u5F97\u3067\u304D\u307E\u305B\u3093\u3067\u3057\u305F\u3002" });
                        }
                    }
                }
                case 'qa':
                    return _jsx(QandA, { block: block }, block.uid);
                case 'html':
                    return _jsx(Html, { block: block }, block.uid);
                default: {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    var _exhaustiveCheck = block;
                }
            }
        }
    };
    return (_jsx(_Fragment, { children: setting.blocks.map(function (block, index) {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            return (block.displayDevice === deviceType ||
                block.displayDevice === 'all' ||
                block.displayDevice === undefined) && (_jsx("section", __assign({ className: "wrapper_block ".concat(block.type, "_block"), id: block.uid, css: [
                    css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n                  display: ", ";\n                  background-color: ", ";\n                  color: ", ";\n                  margin-top: ", ";\n                  margin-bottom: ", ";\n                  width: 100%;\n                  container-type: ", ";\n                "], ["\n                  display: ", ";\n                  background-color: ", ";\n                  color: ", ";\n                  margin-top: ", ";\n                  margin-bottom: ", ";\n                  width: 100%;\n                  container-type: ", ";\n                "])), block.hidden ? 'none' : 'block', ((_a = block === null || block === void 0 ? void 0 : block.colors) === null || _a === void 0 ? void 0 : _a.enable)
                        ? (_b = block === null || block === void 0 ? void 0 : block.colors) === null || _b === void 0 ? void 0 : _b.bg
                        : 'none', ((_c = block === null || block === void 0 ? void 0 : block.colors) === null || _c === void 0 ? void 0 : _c.enable) ? (_d = block === null || block === void 0 ? void 0 : block.colors) === null || _d === void 0 ? void 0 : _d.text : '', ((_e = block === null || block === void 0 ? void 0 : block.verticalSpacing) === null || _e === void 0 ? void 0 : _e.enable) &&
                        ((_f = block === null || block === void 0 ? void 0 : block.verticalSpacing) === null || _f === void 0 ? void 0 : _f.top)
                        ? "".concat(block.verticalSpacing.top, "px")
                        : '', ((_g = block === null || block === void 0 ? void 0 : block.verticalSpacing) === null || _g === void 0 ? void 0 : _g.enable) &&
                        ((_h = block === null || block === void 0 ? void 0 : block.verticalSpacing) === null || _h === void 0 ? void 0 : _h.bottom)
                        ? "".concat(block.verticalSpacing.bottom, "px")
                        : '', block.type === 'html'
                        ? 'inline-size'
                        : 'normal'),
                    scrollMessageSenderStyle(),
                ], onClick: createScrollMessageSender("block-container-".concat(block.uid)) }, { children: _jsx("div", __assign({ css: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n                  max-width: ", ";\n                  padding: ", ";\n                  margin: auto;\n                "], ["\n                  max-width: ", ";\n                  padding: ", ";\n                  margin: auto;\n                "])), block.type === 'map' ? 'none' : '1280px', block.type === 'map' ? '0' : '1rem 10px') }, { children: buildBlock(block) })) }), index));
        }) }));
};
export default SiteContents;
var templateObject_1, templateObject_2;
